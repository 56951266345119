import { FC, memo } from "react";
import { cx } from "@linaria/core";
import { cssIsActive } from "@/styles/utils/Utils";
import { Button } from "@/ui/Button/Button";
import { cssButtonProductToggle } from "@/ui/Button/StyledButton";
import { ButtonVariantsPropsType } from "@/ui/Button/types";
import { GTAG_EVENTS } from "@/utils/constants";
import { gtagSend } from "@/utils/helpers";
const TEXT_NOT_ACTIVE = "Добавить в избранное";
const TEXT_ACTIVE = "Удалить из избранного";
export const ButtonToggleFavorite: FC<{
  isFavorites: boolean;
  isFetching?: boolean;
  toggleFavorite: () => void;
} & Pick<ButtonVariantsPropsType, "size" | "variant" | "isClean">> = memo(({
  isFavorites,
  toggleFavorite,
  isFetching = false,
  size,
  variant = "box",
  isClean
}) => {
  const text = isFavorites ? TEXT_ACTIVE : TEXT_NOT_ACTIVE;
  const toggleHandle = () => {
    if (!isFavorites) {
      gtagSend({
        event: GTAG_EVENTS.ADD_TO_FAVORITES
      });
    }
    toggleFavorite();
  };
  return <Button variant={!!variant ? variant : "link"} icon={isFavorites ? "HeartFilled" : "Heart"} className={cx(cssButtonProductToggle, isFavorites && cssIsActive)} onClick={toggleHandle} isFetching={isFetching} size={size} aria-label={text} seoText={text} role={"button"} type={"button"} title={text} isClean={isClean} />;
});
ButtonToggleFavorite.displayName = "ButtonToggleFavorite";